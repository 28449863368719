<template>
    <layout>
        <div class="account-data mt-5 px-md-10">
            <div class="my-4">
                <router-link :to="{ name: 'account.main', params: {} }" class="dark-text d-flex align-center d-md-none"><v-icon class="mr-3 dark-text">mdi-chevron-left</v-icon> Back to all settings</router-link>
            </div>
            <div class="d-flex justify-space-between align-center">
                <div class="">
                    <h1 class="pr-md-10 heading-2">Account information</h1>
                </div>
                <div class="d-none d-lg-block">
                    <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed :to="{name: 'account.quote'}">Start new quote</v-btn>
                </div>
            </div>
            <v-row class="main mt-5">
                <v-col cols="12" lg="7" class="px-0 px-sm-7">
                    <v-card class="" flat>
                        <v-card-text class="">
                            <h2 class="text-28-60 my-5">User information</h2>
                            <div class="form">
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">First Name</label>
                                            <v-text-field v-model="personal.first_name" background-color="silver" outlined flat hide-details placeholder="John"></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Last Name</label>
                                            <v-text-field v-model="personal.last_name" background-color="silver" outlined flat hide-details placeholder="Wick"></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Email</label>
                                            <v-text-field v-model="personal.email" background-color="silver" outlined flat hide-details placeholder="your@email.com"></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                                <h2 class="text-28-60 my-5">Company information</h2>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Company Name</label>
                                            <v-text-field v-model="personal.company_name" background-color="silver" outlined flat hide-details placeholder="Your Company Name"></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Address</label>
                                            <v-text-field v-model="personal.address" background-color="silver" outlined flat hide-details placeholder="Naples, FL 34101"></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Address 2 (optional)</label>
                                            <v-text-field v-model="personal.address2" background-color="silver" outlined flat hide-details placeholder="(optional)"></v-text-field>
                                            <p class="text-14-45 silver--text mt-2">Apartment, unit, suite, etc.</p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">City or postal code</label>
                                            <v-text-field v-model="personal.id_city" background-color="silver" outlined flat hide-details placeholder="Amelia Court House, VA - 23002"></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Phone Number</label>
                                            <v-text-field v-model="personal.phone" background-color="silver" outlined flat hide-details placeholder="Phone number"></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none mt-8" depressed @click="saveUser">Save changes</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card class="" flat>
                        <v-card-text class="">
                            <h2 class="text-28-60 my-5">Change Password</h2>
                            <div class="form">
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Current Password</label>
                                            <v-text-field v-model="pw_form.password" background-color="silver" outlined flat hide-details></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6"></v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">New Password</label>
                                            <v-text-field v-model="pw_form.new_password" background-color="silver" outlined flat hide-details></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="">
                                            <label for="" class="text-14-45">Confirm New Password</label>
                                            <v-text-field v-model="pw_form.new_password_repeat" background-color="silver" outlined flat hide-details></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none mt-8 mb-3" depressed @click="changePassword">Change password</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="5"></v-col>
            </v-row>
            <v-snackbar
                v-model="success_snackbar"
                color="success"
                centered
            >
                Your personal data is saved
            </v-snackbar>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'
export default {
    middleware: 'auth',
    components: {
        Layout,
    },
    data: () => ({
        personal: {
            first_name: '',
            last_name: '',
            email_name: '',
            company_name: '',
            address: '',
            address2: '',
            zip: '',
            phone: '',
        },
        pw_form: {
            password: '',
            new_password: '',
            new_password_repeat: '',
        },
        success_snackbar: false,
    }),
    mounted(){
        this.personal = this.$store.state.auth.user;
    },
    methods: {
        saveUser(){
            this.axios.put(process.env.VUE_APP_API + 'customers/v1/customer/' + this.personal.id, this.personal).then(response => {
                if(response.data)
                    this.success_snackbar = true;
            });
        },
        changePassword(){
            if(this.pw_form.new_password === this.pw_form.new_password_repeat)
                return this.axios.put(process.env.VUE_APP_API + 'customers/v1/customer/password/' + this.personal.id, this.pw_form);

        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.account-data{
    .v-card{
        .form{
            .v-input, textarea{
                margin-top: 7px;
            }
            .v-text-field{
                fieldset{
                    border: none;
                }
                input::placeholder{
                    font-size: 14px !important;
                    color: $silver-dark !important;
                }
            }
        }
    }
}
@media all and(max-width: 960px){

}

</style>
